import React, { useEffect } from 'react';
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import {products} from './Products';
import './App.css';
import evenlyLogo from './images/evenly_logo.webp';
import evenlyLogoPng from './images/evenly_logo.png';
import cirleArrowLeft from './images/circle-arrow-left-solid.svg';
import bra from './images/bra.jpg';
import fbLogo from './images/fblogo.png';
import instaLogo from './images/instaLogo.png';
import ReactGA from "react-ga4";

function App() {

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  const countries = [
    {value: 'uk', text: 'UK'},
    {value: 'usaCanada', text: 'USA/Canada'},
    {value: 'spainFranceBelgium', text: 'Spain/France/Belgium'},
    {value: 'italy', text: 'Italy'},
    {value: 'australiaNewZealand', text: 'Australia/New Zealand'},
    {value: 'restOfEuropeAsia', text: 'Rest of Europe / Asia'}
  ];

  const bandSizes = [
    {uk: '26', usaCanada: '26', spainFranceBelgium: '70', italy: '-', australiaNewZealand: '4', restOfEuropeAsia: '55'},
    {uk: '28', usaCanada: '28', spainFranceBelgium: '75', italy: '-', australiaNewZealand: '6', restOfEuropeAsia: '60'},
    {uk: '30', usaCanada: '30', spainFranceBelgium: '80', italy: '0', australiaNewZealand: '8', restOfEuropeAsia: '65'},
    {uk: '32', usaCanada: '32', spainFranceBelgium: '85', italy: '1', australiaNewZealand: '10', restOfEuropeAsia: '70'},
    {uk: '34', usaCanada: '34', spainFranceBelgium: '90', italy: '2', australiaNewZealand: '12', restOfEuropeAsia: '75'},
    {uk: '36', usaCanada: '36', spainFranceBelgium: '95', italy: '3', australiaNewZealand: '14', restOfEuropeAsia: '80'},
    {uk: '38', usaCanada: '38', spainFranceBelgium: '100', italy: '4', australiaNewZealand: '16', restOfEuropeAsia: '85'},
    {uk: '40', usaCanada: '40', spainFranceBelgium: '105', italy: '5', australiaNewZealand: '18', restOfEuropeAsia: '90'},
    {uk: '42', usaCanada: '42', spainFranceBelgium: '110', italy: '6', australiaNewZealand: '20', restOfEuropeAsia: '95'},
    {uk: '44', usaCanada: '44', spainFranceBelgium: '115', italy: '8', australiaNewZealand: '22', restOfEuropeAsia: '100'},
  ]

  const cupSizes = [
    {uk: 'A', usaCanada: 'A', spainFranceBelgium: 'A', italy: 'A', australiaNewZealand: 'A', restOfEuropeAsia: 'A'},
    {uk: 'B', usaCanada: 'B', spainFranceBelgium: 'B', italy: 'B', australiaNewZealand: 'B', restOfEuropeAsia: 'B'},
    {uk: 'C', usaCanada: 'C', spainFranceBelgium: 'C', italy: 'C', australiaNewZealand: 'C', restOfEuropeAsia: 'C'},
    {uk: 'D', usaCanada: 'D', spainFranceBelgium: 'D', italy: 'D', australiaNewZealand: 'D', restOfEuropeAsia: 'D'},
    {uk: 'DD', usaCanada: 'DD/E', spainFranceBelgium: 'E', italy: 'DD', australiaNewZealand: 'DD', restOfEuropeAsia: 'E'},
    {uk: 'E', usaCanada: 'DDD/F', spainFranceBelgium: 'F', italy: 'E', australiaNewZealand: 'E', restOfEuropeAsia: 'F'},
    {uk: 'F', usaCanada: 'DDDD/G', spainFranceBelgium: 'G', italy: 'F', australiaNewZealand: 'F', restOfEuropeAsia: 'G'},
    {uk: 'FF', usaCanada: 'H', spainFranceBelgium: 'H', italy: 'FF', australiaNewZealand: 'FF', restOfEuropeAsia: 'H'},
    {uk: 'G', usaCanada: 'I', spainFranceBelgium: 'I', italy: 'G', australiaNewZealand: 'G', restOfEuropeAsia: 'I'},
    {uk: 'GG', usaCanada: 'J', spainFranceBelgium: 'J', italy: 'GG', australiaNewZealand: 'GG', restOfEuropeAsia: 'J'},
    {uk: 'H', usaCanada: 'K', spainFranceBelgium: 'K', italy: 'H', australiaNewZealand: 'H', restOfEuropeAsia: 'K'},
    {uk: 'HH', usaCanada: 'L', spainFranceBelgium: 'L', italy: 'HH', australiaNewZealand: 'HH', restOfEuropeAsia: 'L'},
    {uk: 'J', usaCanada: 'M', spainFranceBelgium: 'M', italy: 'J', australiaNewZealand: 'J', restOfEuropeAsia: 'M'},
    {uk: 'JJ', usaCanada: 'N', spainFranceBelgium: 'N', italy: 'JJ', australiaNewZealand: 'JJ', restOfEuropeAsia: 'N'},
    {uk: 'K', usaCanada: 'O', spainFranceBelgium: 'O', italy: 'K', australiaNewZealand: 'K', restOfEuropeAsia: 'O'},
  ]

  const differences = [
    {value: '+1', text: 'Up to one cup size'},
    {value: '1-2', text: 'Between 1-2 cup sizes'},
    {value: '2-3', text: 'Between 2-3 cup sizes'},
  ];

  const [country, setCountry] = React.useState('uk');
  const [bands, setBands] = React.useState(bandSizes.map(band => band.uk));
  const [cups, setCups] = React.useState(cupSizes.map(cup => cup.uk));
  const [cup, setCup] = React.useState('A');
  const [band, setBand] = React.useState('26');
  const [difference, setDifference] = React.useState('+1');
  const [ukband, setUkBand] = React.useState('26');
  const [ukcup, setUkCup] = React.useState('A');

  const [step, setStep] = React.useState(1);

  const goToNextStep = e => {
    e.preventDefault();
    setStep(step + 1);
    window.scrollTo(0, 0);
    document.getElementById("fitFinder").scrollIntoView();
  }

  const goToPrevStep = e => {
    e.preventDefault();
    setStep(step - 1);
    window.scrollTo(0, 0);
    document.getElementById("fitFinder").scrollIntoView();
  }

  //Listen for Bands change to update default Band value
  React.useEffect(() => {
    setBand(bands[0]);
  }, [bands]);

  React.useEffect(() => {
    setCup(cups[0]);
  }, [cups]);


  //Listen for difference change and trigger product lookup
  // React.useEffect(() => {
  //   console.log(difference, ukband, ukcup);
  // }, [difference, ukband, ukcup, Products]);

  return (
    <div className="App">
      <header>
        <div className="callout">
          <p>
            <a className="link link--large link--white bold" href="https://www.evenlybreasts.com/">
              <img class="back-icon filter-white" src={cirleArrowLeft} alt="Back to main site icon"></img>
              Back to Evenly website
            </a>
          </p>
        </div>
        <a href="https://www.evenlybreasts.com/">
          <picture>
            <source srcset={evenlyLogo} type="image/webp"></source>
            <source srcset={evenlyLogoPng} type="image/png"></source>
            <img src={evenlyLogoPng} alt="evenly logo"></img>
          </picture>
        </a>
      </header>
      <span id="fitFinder"></span>

      <div className="form-container">
        <div className="heading">
          <h1>Fit Finder</h1>
        </div>

        <div className="form">
          <form className="fitFinderForm">

            { step < 4 && <span className='step'>Step {step} of 3</span> }
            { step === 4 && <span className='step'>Result</span> }

            { step === 1 && <QuestionOne country={country} countries={countries} setCountry={setCountry} band={band} setBand={setBand} setUkBand={setUkBand} bands={bands} setBands={setBands} bandSizes={bandSizes}  cup={cup} setCup={setCup} setUkCup={setUkCup} cups={cups} setCups={setCups} cupSizes={cupSizes}/>}

            { step === 2 && <QuestionTwo country={country} setCup={setCup} setBand={setBand} band={band} bands={bands} bandSizes={bandSizes} setUkBand={setUkBand} cup={cup} cups={cups} cupSizes={cupSizes} setUkCup={setUkCup}/>}

            { step === 3 && <QuestionThree setDifference={setDifference} difference={difference} differences={differences} />}

            { step === 4 && <Result band={band} cup={cup} ukcup={ukcup} ukband={ukband} difference={difference} products={products}/> }

            <div className='buttons'>
              { step !== 1 && <button className='button' onClick={goToPrevStep}>Previous Step</button> }
              { step < 3 && <button className='button' onClick={goToNextStep}>Next Step</button> }
              { step === 3 && <button className='button button--blue bold' onClick={goToNextStep}>Result</button> }
            </div>
          </form>
        </div>
      </div>

      <CookieConsent
        location="bottom"
        cookieName="cookieConsent"
        contentClasses="ccContent"
        style={{ background: "#FFFFFF", color: "#000000"}}
        disableButtonStyles
        expires={150}
        enableDeclineButton
        buttonText="Accept"
        declineButtonText="Decline All"
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        We use cookies on our website to see how you interact with it. By accepting, you agree to our use of such cookies. <a className="link" href="https://www.evenlybreasts.com/privacy-policy" target="_blank" rel="noreferrer">See privacy policy.</a>
      </CookieConsent>
    </div>
  );
}

function QuestionOne(props) {

  const handleCountryChange = e =>{
    props.setCountry(e.target.value);
    props.setUkBand('28');
    props.setUkCup('A');
    props.setBands(props.bandSizes.flatMap(band => band[e.target.value] === '-' ? [] : band[e.target.value]));
    props.setCups(props.cupSizes.flatMap(cup => cup[e.target.value] === '-' ? [] : cup[e.target.value]));
  }

  return (
    <div>
      <label>Question 1: Which country are you in?</label>

      <p>Evenly sizes are based on UK bra sizes. To start, we need to know where you live so we can convert your bra size to the UK equivalent.</p>

      <p>
        <select value={props.country} onChange={handleCountryChange}>
          {props.countries.map(country => {
            return (<option key={country.value} value={country.value}>{country.text}</option>);
          })}
        </select>
      </p>
    </div>
  )
}

function QuestionTwo(props) {

  const handleCupChange = e =>{
    props.setCup(e.target.value);
    let index = props.cupSizes.findIndex(cup => cup[props.country] === e.target.value);
    props.setUkCup(props.cupSizes[index].uk);
  }

  const handleBandChange = e =>{
    props.setBand(e.target.value);
    let index = props.bandSizes.findIndex(band => band[props.country] === e.target.value);
    props.setUkBand(props.bandSizes[index].uk);
  }

  return (
    <div>
      <label>Question 2: What bra size fits your biggest breast well?</label>

      <p>
        A good bra fit is where the material of the cup will be sitting comfortably flat
        to the skin of your larger breast with no gaping or bulge over your bra cup and no digging in at the side.
      </p>

      <p>
        If you're not sure you're wearing the right bra size, we've teamed up
        with Brarista to offer virtual bra fittings. No undressing necessary!
      </p>

      <p><a className="link" target="_blank" rel="noreferrer" href="https://calendly.com/brarista_booking/evenly-breasts-x-brarista-bespoke-fitting?month=2022-01">Click here to book your Brarista fitting.</a></p>

      <p>
        <label className="sub-label">Choose your Band size:</label>

        <select value={props.band} onChange={handleBandChange}>
          {props.bands.map(band => {
            return (<option key={band} value={band}>{band}</option>);
          })}
        </select>
      </p>

      <p>
        <label className="sub-label">Choose your Cup size:</label>

        <select value={props.cup} onChange={handleCupChange}>
          {props.cups.map(cup => {
            return (<option key={cup} value={cup}>{cup}</option>);
          })}
        </select>
      </p>
    </div>
  )
}

function QuestionThree(props) {
  const handleDifferenceChange = e =>{
    props.setDifference(e.target.value);
  }

  return (
    <div>
      <label>Question 3: What do you estimate to be the difference in cup sizes between your two breasts?</label>

      <img src={bra} alt="Bra fit" />

      <p>
        This part can be tricky! You can get a good idea of the cup size difference between your breasts by looking at the gap
        between the bra cup of a non-stretchy bra and the skin of your smallest breast. Each 0.5cm in gape roughly equates to a
        cup size difference (you can use your fingers to help measure - one little finger width is roughly one cm).
      </p>

      <p>
        Alternatively, if you know from past experience which bras typically fit your smaller side best
        you can calculate your cup size difference that way. For example, if a 34C fits your smaller breast
        perfectly but you need a 34D to accommodate your fuller breast, then that is 1 cup size difference.
        Always be sure to compare bras with the same band size (the number) when using this method.
      </p>

      <p>Still not sure, or you have more than a 3 cup size difference? <a class="link" href="https://www.evenlybreasts.com/contact">Get in touch with us to help us find your best fit!</a></p>

      <p>
        <select value={props.difference} onChange={handleDifferenceChange}>
          {props.differences.map(difference => {
            return (<option key={difference.value} value={difference.value}>{difference.text}</option>);
          })}
        </select>
      </p>
    </div>
  )
}

function Result(props) {

  const productId = props.ukband + props.ukcup + props.difference;
  
  let index = props.products.findIndex(product => product.id === productId);

  const rsize = props.products[index].rsize;
  const url = props.products[index].url;

  if(rsize !== 'No Fit') {
    return (
<div>
        <p>Tit Tit Hooray! We’ve found your breast fit!</p>

        <p>
          Based on your answers, the most suitable Evenly bra filler for you is:
        </p>

        <p className="recommended-size">SIZE { rsize }</p>

        <p className='bold'>
          Make a note of this now. You can take a screenshot, or jot it down on a bit of paper the old fashioned way.
        </p>

        <p>
          With your size at the ready, click on the button below and it will take you to the appropriate Bra Balancer&#8482; or Subtle Shaper&#8482; range.
          Once on that page, you will need to select the size recommended above from our drop-down menu.
        </p>
      

        <a className="button button--blue bold bottom-margin-zero" target="_blank" rel="noreferrer" href={ url }>Shop Your Size</a>
        <p>
          Although we're hopeful that this size will work for you, we always recommend ordering a couple of different sizes to try if possible as bra sizes really vary between brands, countries and styles. By ordering a couple of different sizes you have a better chance of finding the perfect fit for you and can just return the unwanted sizes to us for a full refund - or keep them for those inevitable monthly breast size fluctuations! 
        </p>
        <p>If you have any questions you can <a className="link" target="_blank" rel="noreferrer" href="https://www.evenlybreasts.com/contact">contact us here -we're always happy to help!</a></p>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          Oh tits! We're not able to find the perfect size for you using our standard sizing charts, however don't fret we can often still find one that will work for you.
        </p>
        <p>
          Please <a class="link" href="https://www.evenlybreasts.com/contact">get in touch </a> and let us know your bra size and estimated cup size difference and we'll do our best to find the perfect fit for you. 
        </p>
        <p>
          We're always looking to expand our sizing range, do keep an eye on our social media for updates. 
        </p>
        <a className="link social-box" target="_blank" rel="noreferrer" href="https://www.facebook.com/weareevenly/">
          <img className="social-icon" src={fbLogo} alt="Facebook Social Icon"/>
          <span className="social-text">@weareevenly</span>
        </a>

        <a className="link social-box" target="_blank" rel="noreferrer" href="https://www.instagram.com/weareevenly/">
          <img className="social-icon" src={instaLogo} alt="Instagram Social Icon" />
          <span className="social-text">@weareevenly</span>
        </a>

        <p>
          You can get support from fellow uneven boob owners and also share tips and stories in our free and private Facebook group
          &nbsp;
          <a className="link" target="_blank" rel="noreferrer" href="https://www.facebook.com/weareevenly/">
            The Uneven Titty Committee
          </a>
          &nbsp; and we have lots of useful information on &nbsp;
          <a className="link" target="_blank" rel="noreferrer" href="https://www.evenlybreasts.com/blog">
            The Balanced Blog
          </a>
        </p>

      </div>
    );
  }
}

export default App;
